
// @ts-nocheck


export const localeCodes =  [
  "nl",
  "fr",
  "en"
]

export const localeLoaders = {
  "nl": [],
  "fr": [],
  "en": []
}

export const vueI18nConfigs = [
  () => import("../../../../config/i18n-vue.config.ts?hash=62d2cf8d&config=1" /* webpackChunkName: "__config_i18n_vue_config_ts_62d2cf8d" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "./server/localeDetector.ts",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": true
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./config/i18n-vue.config.ts",
  "locales": [
    {
      "code": "nl",
      "iso": "nl",
      "name": "Nederlands"
    },
    {
      "code": "fr",
      "iso": "fr",
      "name": "Français"
    },
    {
      "code": "en",
      "iso": "en",
      "name": "English"
    }
  ],
  "defaultLocale": "fr",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://pro.vanuxeem.com",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "example/[slug]": {
      "nl": "/voorbeeld/[slug]",
      "fr": "/exemple/[slug]",
      "en": "/example/[slug]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "nl",
    "iso": "nl",
    "name": "Nederlands",
    "files": []
  },
  {
    "code": "fr",
    "iso": "fr",
    "name": "Français",
    "files": []
  },
  {
    "code": "en",
    "iso": "en",
    "name": "English",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
