import type { WebhookPayload } from "@esc-bv/ebs-nuxt-datocms-preview/server/api/datocms/preview-links";

export default defineAppConfig({
  /**
   * Used in page titles.
   */
  projectName: "Vanuxeem",

  /**
   * Color for manifest that goes along with the favicons.
   */
  themeColorLightMode: "#275DA2",

  /**
   * Color for manifest that goes along with the favicons.
   */
  themeColorDarkMode: "#0F3042",

  datoCmsPreview: {
    webhookSecretValue: "15e3421d248b2f75",
    cryptoPassword: "acfa0db3d26c42afa79f8da1b3197048",
    generatePreviewUrl: generatePreviewUrl,
  },

  /**
   * NuxtUI customizations.
   */
  ui: {
    primary: "blue",
    gray: "ash",
    badge:
    {
      variant: {
        soft: "bg-{color}-50 text-{color}-900",
      },
    },
    button: {
      base: "text-sm !py-[12px] lg:!py-[8px] px-lg",
      variant: {
        ghost: "text-{color}-600",
        outline: "text-{color}-600",
        solid: "bg-{color}-600 hover:bg-{color}-500",
      },
      icon: {
        base: "!size-5 xl:!size-6",
        // SVG icon is already spinning
        loading: "",
      },
      default: { loadingIcon: "i-svg-spinners-ring-resize" },
    },
    card: {
      base: "",
      background: "bg-white dark:bg-gray-900",
      divide: "",
      ring: "", // 'ring-1 ring-gray-200 dark:ring-gray-800'
      rounded: "rounded-md",
      shadow: "shadow-lg shadow-ash-900/10",
    },
    container: {
      base: "w-full",
      constrained: "max-w-[1440px]",
    },
    checkbox: {
      base: "cursor-pointer",
      label: "cursor-pointer",
    },
    dropdown:
    {
      padding: "p-xl flex flex-col gap-md",
      item:
      {
        base: "[&>a]:p-0 hover:[&>a]:text-blue-700",
        padding: "p-0",
        active: "bg-transparent text-blue-700",
      },
    },
    formGroup: {
      wrapper: "mb-xl",
      label: {
        base: "text-dark",
        required: "after:ms-xs after:text-dark",
      },
      container: "mt-xs",
    },
    input: {
      base: "text-sm !py-[12px] lg:!py-[10px] px-lg ring-ash-300",
    },
    pagination: {
      default: {
        prevButton: {
          icon: "i-ic-baseline-chevron-left",
        },
        nextButton: {
          icon: "i-ic-baseline-chevron-right",
        },
      },
    },
    radio: {
      color: "text-{color}-600",
    },
    select: {
      base: "!py-[12px] lg:!py-[10px] px-lg",
    },
    selectMenu: {
      select: "!py-[12px] lg:!py-[10px] px-lg cursor-pointer",
      option: {
        base: "cursor-pointer",
      },
    },
    textarea: {
      base: "!py-[12px] lg:!py-[10px] px-lg",
    },
  },
});

// this function knows how to convert a DatoCMS record into a canonical URL within the website
function generatePreviewUrl({ item, itemType, locale }: WebhookPayload) {
  const localePrefix = locale.toLowerCase();
  const slug = (item.attributes.slug && item.attributes.slug[locale]) || "";
  switch (itemType.attributes.api_key) {
    case "legal_page":
      return `/${localePrefix}${getSlugPrefix("legal", localePrefix)}/${slug}`;
    case "page":
      return `/${localePrefix}/${slug === "home" ? "" : slug}`;
    case "layout":
      return `/${localePrefix}/`;
    default:
      return null;
  }
}
