<script setup lang="ts">
const { projectName, themeColorLightMode } = useAppConfig();

// FALLBACK
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${projectName}` : projectName;
  },
});
</script>

<template>
  <Head>
    <!-- https://favicon.inbrowser.app/tools/favicon-generator -->
    <Link rel="apple-touch-icon" href="/apple-touch-icon.png" />
    <Link rel="icon" href="/favicon.ico" sizes="48x48" />
    <Link rel="icon" href="/favicon.svg" sizes="any" type="image/svg+xml" />
    <Link rel="manifest" href="/site.webmanifest" />

    <!-- only choose one theme-color -->
    <Meta
      name="theme-color"
      :content="themeColorLightMode"
      media="(prefers-color-scheme: light)"
    />
    <!-- <Meta
      name="theme-color"
      :content="themeColorDarkMode"
      media="(prefers-color-scheme: dark)"
    /> -->
  </Head>
  <NuxtLoadingIndicator :color="themeColorLightMode" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <UModals />
  <UNotifications />
</template>
