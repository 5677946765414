import { SortOrder, SortableFields } from "~/graphql/propeller/generated";
import type { FilterGroupMultiCheck, FilterGroupMultiSelect } from "~/models/filters";
import type { ProductListItem, ProductSearchResultsView } from "~/models/products";
import type { Pagination } from "~/models/pagination";
import type { SearchProductsRequest } from "~/server/api/ecom/products/index.get";
import type { CustomCart } from "~/server/utils/mapping/cartMapping";

type CatalogFilterTypes = FilterGroupMultiCheck | FilterGroupMultiSelect;

export type SearchProductsCriteria = Omit<SearchProductsRequest, "locale" | "searchType">;

export function useCart(): Ref<CustomCart> {
  return useState(
    "cart",
    () => ref({
      items: null,
      products: [] as ProductListItem[],
    } as CustomCart),
  );
}

export function useCatalogFilters(): Ref<Array<CatalogFilterTypes>> {
  return useState<Array<CatalogFilterTypes>>("catalog-filters", () => reactive<Array<CatalogFilterTypes>>([]));
}

export function useCatalogPagination(): Ref<Pagination> {
  return useState<Pagination>(
    "catalog-pagination",
    () => ref<Pagination>({
      pageSize: 0,
      totalItems: 0,
    }),
  );
}

export function useCatalogProducts(): Ref<ProductListItem[]> {
  return useState<ProductListItem[]>(
    "catalog-products",
    () => shallowRef<ProductListItem[]>([]),
  );
}

export function useCatalogSearchQuery(): Ref<SearchProductsCriteria> {
  return useState<SearchProductsCriteria>(
    "catalog-search-query",
    () => reactive<SearchProductsCriteria>({
      page: 1,
      sortField: SortableFields.name,
      sortOrder: SortOrder.asc,
    }),
  );
}

export function useCatalogViewMode(defaultValue: ProductSearchResultsView): Ref<ProductSearchResultsView> {
  return useState<ProductSearchResultsView>(
    "product-search-active-view",
    () => useCookie(
      "product-search.active-view",
      {
        default: () => defaultValue,
        sameSite: "lax",
        maxAge: 86400 * 365, // 1 year
      },
    ),
  );
}

export function useCheckoutAvailableDates(): Ref<Date[]> {
  return useState<Date[]>("checkout-available-dates", () => ref([]));
}

export function useReferrer(): Ref<string | undefined> {
  return useState<string | undefined>("referrer", () => ref(undefined));
}

export function useSearchTerm(): Ref<string> {
  return useState<string>("search-term", () => ref(""));
}
